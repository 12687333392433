@import "alembic";

td {
  border-top: 1px solid #ededed;
}
table {
  border-bottom: 1px solid #ededed;
}

.share ul {
  display: flex;
  list-style: none;
  & > li {
    margin-right: .3rem;
    & * {
      vertical-align: bottom;
    }
  }
}
